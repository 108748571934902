<template>
    <q-dialog ref="dialog" @hide="onDialogHide" :no-esc-dismiss="isSubmitting" :no-backdrop-dismiss="isSubmitting">
        <q-card class="q-dialog-plugin q-dialog-size-sm">
            <q-form @submit="onOKClick">
                <q-card-section>
                    <div class="row items-start no-wrap q-gutter-x-sm">
                        <div class="text-h6">{{ title }}</div>
                        <q-space />
                        <q-btn icon="close" @click="hide" :disable="isSubmitting" flat round dense />
                    </div>
                </q-card-section>
                <q-card-section class="q-gutter-y-xs">
                    <e-select-adresse @selected="selected" style="padding-bottom: 20px" options-dense dense />
                    <q-select
                        v-if="typeAdresse"
                        v-model="selectedTypeAdresse"
                        label="Type"
                        option-label="nom"
                        option-value="id"
                        :loading="isLoadingTypesAdresses"
                        :options="typesAdresses"
                        :rules="[(val) => !!val || 'Le type est obligatoire']"
                        lazy-rules
                        options-dense
                        dense
                    />
                    <div class="row q-col-gutter-x-md">
                        <q-input
                            v-model="adresseLocal.numCivique"
                            label="Numéro civique"
                            class="col-9"
                            :rules="[(val) => !!val || isCreationContrat || 'Le numéro civique est obligatoire']"
                            lazy-rules
                            dense
                        />
                        <q-input v-model="adresseLocal.appartement" label="Appartement" class="col-3" dense />
                    </div>
                    <div class="row q-col-gutter-x-md">
                        <q-input v-model="adresseLocal.rue" label="Rue" class="col" :rules="[(val) => !!val || isCreationContrat || 'La rue est obligatoire']" lazy-rules dense />
                        <q-input v-model="adresseLocal.ville" label="Ville" class="col" :rules="[(val) => !!val || 'La ville est obligatoire']" lazy-rules dense />
                    </div>
                    <div class="row q-col-gutter-x-md">
                        <q-input v-model="adresseLocal.province" label="Prov./État" class="col" :rules="[(val) => !!val || 'La province/état est obligatoire']" lazy-rules dense />
                        <q-input v-model="adresseLocal.pays" label="Pays" class="col" :rules="[(val) => !!val || 'Le pays est obligatoire']" lazy-rules dense />
                    </div>
                    <q-input v-model="adresseLocal.codePostal" label="Code postal" :rules="[(val) => !!val || isCreationContrat || 'Le code postal est obligatoire']" lazy-rules dense />
                    <q-input v-model="adresseLocal.complement" label="Complément" dense />
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" label="Annuler" :disable="isSubmitting" @click="hide" flat />
                    <q-btn color="primary" type="submit" label="Confirmer" :loading="isSubmitting" flat />
                </q-card-actions>
            </q-form>
        </q-card>
    </q-dialog>
</template>

<script>
import ESelectAdresse from "./ESelectAdresse.vue";

export default {
    name: "e-dialog-adresse",
    components: {
        ESelectAdresse
    },
    props: {
        title: {
            type: String,
            default: "Adresse"
        },
        typeAdresse: Boolean,
        enregistrer: {
            type: Boolean,
            default: true
        },
        adresse: Object,
        callback: Function,
        isCreationContrat: {
            type: Boolean,
            default: false
        }
    },
    emits: ["ok", "hide"],
    data() {
        return {
            isSubmitting: false,
            isLoadingTypesAdresses: true,
            typesAdresses: [],
            selectedTypeAdresse: null,
            adresseLocal: {
                numCivique: this.adresse?.numCivique || "",
                appartement: this.adresse?.appartement || null,
                rue: this.adresse?.rue || "",
                ville: this.adresse?.ville || "",
                province: this.adresse?.province || "",
                pays: this.adresse?.pays || "",
                codePostal: this.adresse?.codePostal || "",
                complement: this.adresse?.complement || null,
                latitude: this.adresse?.latitude || null,
                longitude: this.adresse?.longitude || null
            }
        };
    },
    computed: {
        isChanged() {
            return (
                this.adresse?.numCivique !== this.adresseLocal.numCivique ||
                this.adresse?.appartement !== this.adresseLocal.appartement ||
                this.adresse?.rue !== this.adresseLocal.rue ||
                this.adresse?.ville !== this.adresseLocal.ville ||
                this.adresse?.province !== this.adresseLocal.province ||
                this.adresse?.pays !== this.adresseLocal.pays ||
                this.adresse?.codePostal !== this.adresseLocal.codePostal ||
                this.adresse?.complement !== this.adresseLocal.complement ||
                this.adresse?.latitude !== this.adresseLocal.latitude ||
                this.adresse?.longitude !== this.adresseLocal.longitude
            );
        }
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onDialogHide() {
            this.$emit("hide");
        },
        async onOKClick() {
            this.isSubmitting = true;

            try {
                const geocodes = await this.$api.googleApi.getGeocode({
                    adresse: this.$filters.adresse(this.adresseLocal, { appartement: false, complement: false })
                });

                let geocode, adresse;

                if (geocodes.length) {
                    const { lat, lng } = geocodes[0].geometry.location;

                    this.adresseLocal.latitude = lat;
                    this.adresseLocal.longitude = lng;

                    geocode = geocodes[0];
                }

                if (this.enregistrer) {
                    let id = this.adresse?.id;

                    if (!id || this.isChanged) id = await this.$api.adresses.ajouterAdresse(this.adresseLocal);

                    adresse = { ...this.adresseLocal, id };

                    if (this.typeAdresse) adresse.type = this.selectedTypeAdresse;

                    if (this.callback) await this.callback(adresse);
                } else {
                    adresse = { ...this.adresseLocal };

                    if (this.typeAdresse) adresse.type = this.selectedTypeAdresse;
                }

                this.$emit("ok", { adresse, geocode });
                this.hide();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de l'enregistrement de l'adresse." });
            } finally {
                this.isSubmitting = false;
            }
        },
        selected(adresse) {
            this.adresseLocal.numCivique = adresse.numCivique;
            this.adresseLocal.appartement = null;
            this.adresseLocal.rue = adresse.rue;
            this.adresseLocal.ville = adresse.ville;
            this.adresseLocal.province = adresse.province;
            this.adresseLocal.pays = adresse.pays;
            this.adresseLocal.codePostal = adresse.codePostal;
            this.adresseLocal.complement = null;
        }
    },
    async mounted() {
        if (this.typeAdresse) {
            try {
                this.typesAdresses = await this.$api.adresses.getTypesAdresses();
                this.selectedTypeAdresse = this.adresse ? this.typesAdresses.find((x) => x.id === this.adresse.type?.id) : this.typesAdresses.find((x) => x.estDefaut);
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des types d'adresses." });
            } finally {
                this.isLoadingTypesAdresses = false;
            }
        }
    }
};
</script>
