import { defineStore } from "pinia";

import date from 'quasar/src/utils/date.js';;
const { formatDate } = date;

import api from "@/api";

import { useAuthStore } from "./index";

export const useProspectStore = defineStore("prospect", {
    state: () => ({
        //Route
        retourRoutePath: null,
        idProspect: null,
        estRechercheParTelephone: false,

        //Prospect
        isLoading: false,
        idGroupeProjetPropose: null,
        compagnie: null,
        personnes: [],
        conseiller: null,
        agence: null,
        classificationVendeur: 0,
        classificationSysteme: null,
        questions: [],
        suivis: [],
        taches: [],
        projets: [],

        //Misc
        typesTelephones: []
    }),
    getters: {
        //Droits
        readOnly: () => useAuthStore().droit("FicheProspect3") !== 2,
        peutModifierConseillerAgence: () => useAuthStore().droit("FicheProspect3/ModifierBureauEtVendeur") === 2,
        peutTransfererCompagnieErp: () => useAuthStore().droit("FicheProspect3/BtnTransfertProspectCompagnie") === 2,
        peutVoirOngletProjets: () => useAuthStore().droit("FicheProjets") > 0,
        peutModifierProjet: () => useAuthStore().droit("FicheProjets") > 0,

        //Prospect
        estCompagnie: (state) => !state.isLoading && !!state.compagnie,
        dateProchainSuivi: (state) => {
            const taches = state.taches.filter((x) => x.type?.nom === "Rappel" && x.tacheFaiteLe == null);
            return taches.length ? taches[0].dateDebut : null;
        }
    },
    actions: {
        async getProspect() {
            const {
                idGroupeProjetPropose,
                compagnie,
                personnes,
                conseiller,
                agence,
                classificationVendeur,
                classificationSysteme,
                questions,
                suivis,
                taches,
                projets,
                typesTelephones
            } = await api.prospects.getInfosProspect({
                idProspect: this.idProspect,
                estRechercheParTelephone: this.estRechercheParTelephone
            });

            if (this.peutVoirOngletProjets && projets.some((x) => x.estimations.length)) {
                const projetsTcost = await api.tcost.getProjets({
                    ids: projets.flatMap((x) => x.estimations.map((e) => e.idProjetTcost)).join(",")
                });

                projets.forEach((p) => {
                    p.isExpanded = projets.filter((x) => x.etat === p.etat)[0]?.id === p.id;
                    p.estimations = p.estimations.filter((e) => projetsTcost.some((pt) => pt.id === e.idProjetTcost));
                });

                for (let i = 0; i < projets.length; i++) {
                    for (let j = 0; j < projets[i].estimations.length; j++) {
                        const projetTcost = projetsTcost.find((x) => x.id === projets[i].estimations[j].idProjetTcost),
                            dateFin = projets[i].estimations[j].dateFin || projetTcost.dateFin;

                        projets[i].estimations[j] = {
                            ...projets[i].estimations[j],
                            ...projetTcost,
                            dateFin
                        };
                    }
                }
            }

            //Prospect
            this.idGroupeProjetPropose = idGroupeProjetPropose;
            this.compagnie = compagnie;
            this.personnes = personnes;
            this.conseiller = conseiller;
            this.agence = agence;
            this.classificationVendeur = classificationVendeur;
            this.classificationSysteme = classificationSysteme;
            this.questions = questions;
            this.suivis = suivis;
            this.taches = taches;
            this.projets = projets;

            //Misc
            this.typesTelephones = typesTelephones;
        },
        async getProjetsTcost() {
            const projetsTcost = await api.tcost.getProjets({
                ids: this.projets.flatMap((x) => x.estimations.map((e) => e.idProjetTcost)).join(",")
            });

            this.projets.forEach((p) => {
                p.estimations = p.estimations.filter((e) => projetsTcost.some((pt) => pt.id === e.idProjetTcost));
            });

            for (let i = 0; i < this.projets.length; i++) {
                for (let j = 0; j < this.projets[i].estimations.length; j++) {
                    const projetTcost = projetsTcost.find((x) => x.id === this.projets[i].estimations[j].idProjetTcost),
                        dateFin = this.projets[i].estimations[j].dateFin || projetTcost.dateFin;

                    this.projets[i].estimations[j] = {
                        ...this.projets[i].estimations[j],
                        ...projetTcost,
                        dateFin
                    };
                }
            }
        },
        async modifierClassificationVendeur({ classificationVendeur }) {
            this.classificationVendeur = classificationVendeur;

            await api.prospects.modifierClassificationConseiller({
                idProspect: this.idProspect,
                classification: classificationVendeur
            });
        },
        async modifierConseiller({ conseiller, dateRappel }) {
            await api.prospects.modifierConseillerProspect({
                idProspect: this.idProspect,
                idSite: conseiller?.id,
                idEmploye: conseiller?.employe?.id
            });

            if (conseiller && conseiller.employe && !!dateRappel) {
                const taches = await api.prospects.ajouterRappelSuiviProspect({
                    idProspect: this.idProspect,
                    idEmploye: conseiller.employe.id,
                    dateRappel
                });

                this.taches.push(...taches);
                this.taches.sort((a, b) => a.dateDebut.localeCompare(b.dateDebut));
            }

            this.conseiller = conseiller;
        },
        async modifierAgence({ agence }) {
            await api.prospects.modifierAgenceProspect({
                idProspect: this.idProspect,
                idSite: agence?.id,
                idEmploye: agence?.employe?.id
            });

            this.agence = agence;
        },
        async modifierReponseQuestion({ idQuestion, reponses }) {
            const question = this.questions.find((x) => x.id === idQuestion);

            this.classificationSysteme = await api.prospects.modifierReponseQuestionSondageProspect({
                idProspect: this.idProspect,
                idQuestion,
                idsReponses: reponses.map((x) => ({ Id: x.id }))
            });

            question.reponses.forEach((x) => (x.estSelectionner = reponses.some((r) => r.id === x.id)));
        },
        async modifierPrenomNomPersonne({ id, prenom, nom }) {
            await api.prospects.modifierNomPersonne({
                idPersonne: id,
                prenom,
                nom
            });

            const personne = this.personnes.find((x) => x.id === id);
            personne.prenom = prenom;
            personne.nom = nom;
        },
        async modifierSexePersonne({ id, sexe }) {
            await api.prospects.modifierSexePersonne({
                idPersonne: id,
                sexe
            });

            const personne = this.personnes.find((x) => x.id === id);
            personne.sexe = sexe;
        },
        async ajouterTelephonePersonne({ idPersonne, numero, poste, type, estPrincipal }) {
            const id = await api.prospects.ajouterTelephonePersonne({
                idPersonne,
                numero,
                poste,
                idTypeTelephone: type.id,
                estPrincipal
            });

            const personne = this.personnes.find((x) => x.id === idPersonne);

            const telephone = {
                id,
                numero,
                poste,
                type,
                estPrincipal,
                format: "(###) ###-####"
            };

            personne.telephones.push(telephone);

            return telephone;
        },
        async modifierTelephonePersonne({ idPersonne, idTelephone, numero, poste, type, estPrincipal }) {
            await api.prospects.modifierTelephonePersonne({
                idTelephonePersonne: idTelephone,
                numero,
                poste,
                idTypeTelephone: type.id,
                estPrincipal
            });

            const personne = this.personnes.find((x) => x.id === idPersonne),
                telephone = personne.telephones.find((x) => x.id === idTelephone);

            telephone.numero = numero;
            telephone.poste = poste;
            telephone.type = type;
            telephone.estPrincipal = estPrincipal;
        },
        async supprimerTelephonePersonne({ idPersonne, idTelephone }) {
            await api.prospects.supprimerTelephonePersonne({
                idTelephonePersonne: idTelephone
            });

            const personne = this.personnes.find((x) => x.id === idPersonne);

            personne.telephones.splice(
                personne.telephones.findIndex((x) => x.id === idTelephone),
                1
            );
        },
        async ajouterAdressePersonne({ idPersonne, adresse }) {
            const id = await api.prospects.ajouterAdressePersonne({
                idPersonne,
                idTypeAdresse: adresse.type.id,
                idAdresse: adresse.id
            });

            const adressePersonne = { ...adresse, id };

            const personne = this.personnes.find((x) => x.id === idPersonne);
            personne.adresses.push(adressePersonne);

            return adressePersonne;
        },
        async supprimerAdressePersonne({ idPersonne, idAdresse }) {
            await api.prospects.supprimerAdressePersonne({
                idAdressePersonne: idAdresse
            });

            const personne = this.personnes.find((x) => x.id === idPersonne);
            personne.adresses.splice(
                personne.adresses.findIndex((x) => x.id === idAdresse),
                1
            );
        },
        async ajouterCourrielPersonne({ idPersonne, courriel }) {
            const id = await api.prospects.ajouterCourrielPersonne({
                idPersonne,
                courriel
            });

            const personne = this.personnes.find((x) => x.id === idPersonne);

            const objCourriel = {
                id,
                courriel
            };

            personne.courriels.push(objCourriel);

            return objCourriel;
        },
        async modifierCourrielPersonne({ idPersonne, idCourriel, courriel }) {
            await api.prospects.modifierCourrielPersonne({
                idCourrielPersonne: idCourriel,
                courriel
            });

            const personne = this.personnes.find((x) => x.id === idPersonne),
                objCourriel = personne.courriels.find((x) => x.id === idCourriel);

            objCourriel.courriel = courriel;
        },
        async supprimerCourrielPersonne({ idPersonne, idCourriel }) {
            await api.prospects.supprimerCourrielPersonne({
                idCourrielPersonne: idCourriel
            });

            const personne = this.personnes.find((x) => x.id === idPersonne);

            personne.courriels.splice(
                personne.courriels.findIndex((x) => x.id === idCourriel),
                1
            );
        },
        async ajouterPersonneCompagnie({ prenom, nom, telephone, sexe }) {
            const personne = await api.prospects.ajouterPersonneCompagnieProspect({
                idProspect: this.idProspect,
                nom,
                prenom,
                telephone,
                sexe
            });

            this.personnes.push(personne);

            return personne;
        },
        async supprimerPersonneCompagnie({ id }) {
            await api.prospects.supprimerPersonneProspectCompagnie({
                idProspect: this.idProspect,
                idPersonne: id
            });

            this.personnes.splice(
                this.personnes.findIndex((x) => x.id === id),
                1
            );
        },
        async modifierNomCompagnie({ nom }) {
            await api.prospects.modifierNomCompagnie({
                idCompagnie: this.compagnie.id,
                nom
            });

            this.compagnie.nom = nom;
        },
        async ajouterTelephoneCompagnie({ numero, type }) {
            const id = await api.prospects.ajouterTelephoneCompagnieProspect({
                idProspect: this.idProspect,
                numero,
                idTypeTelephone: type.id
            });

            const telephone = {
                id,
                numero,
                type,
                format: "(###) ###-####"
            };

            this.compagnie.telephones.push(telephone);

            return telephone;
        },
        async modifierTelephoneCompagnie({ id, numero, type }) {
            await api.prospects.modifierTelephoneCompagnieProspect({
                idTelephoneCompagnie: id,
                numero,
                idTypeTelephone: type.id
            });

            const telephone = this.compagnie.telephones.find((x) => x.id === id);

            telephone.numero = numero;
            telephone.type = type;
        },
        async supprimerTelephoneCompagnie({ id }) {
            await api.prospects.supprimerTelephoneCompagnieProspect({
                idTelephoneCompagnie: id
            });

            this.compagnie.telephones.splice(
                this.compagnie.telephones.findIndex((x) => x.id === id),
                1
            );
        },
        async modifierAdresseCompagnie({ adresse }) {
            await api.prospects.modifierAdresseCompagnie({
                idProspect: this.idProspect,
                idAdresse: adresse.id
            });

            this.compagnie.adresse = adresse;
        },
        async ajouterCourrielCompagnie({ courriel }) {
            const id = await api.prospects.ajouterCourrielCompagnieProspect({
                idProspect: this.idProspect,
                courriel
            });

            const objCourriel = {
                id,
                courriel
            };

            this.compagnie.courriels.push(objCourriel);

            return objCourriel;
        },
        async modifierCourrielCompagnie({ id, courriel }) {
            await api.prospects.modifierCourrielCompagnieProspect({
                idCourrielCompagnie: id,
                courriel
            });

            const objCourriel = this.compagnie.courriels.find((x) => x.id === id);

            objCourriel.courriel = courriel;
        },
        async supprimerCourrielCompagnie({ id }) {
            await api.prospects.supprimerCourrielCompagnieProspect({
                idCourrielCompagnie: id
            });

            this.compagnie.courriels.splice(
                this.compagnie.courriels.findIndex((x) => x.id === id),
                1
            );
        },
        async supprimerTache({ id }) {
            await api.taches.supprimerTachesJournalieresUsager({
                idTacheJournaliereUsager: id
            });

            this.taches.splice(
                this.taches.findIndex((x) => x.id === id),
                1
            );
        },
        async ajouterProjet({ nom, delaiSignature }) {
            const projet = await api.prospects.ajouterProjet({
                idProspect: this.idProspect,
                nom,
                idEtatGroupe: delaiSignature.id
            });

            this.projets.push(projet);

            return projet;
        },
        async modifierProjet({ id, nom, delaiSignature, idTypeCompetiteur, idCompetiteur, autreRaison }) {
            await api.prospects.modifierProjet({
                idGroupeProjetPropose: id,
                nom,
                idEtatGroupe: delaiSignature.id,
                idTypeCompetiteur,
                idCompetiteur,
                autreRaison: autreRaison || null
            });

            const projet = this.projets.find((x) => x.id === id);

            if (nom) {
                projet.nom = nom;
            }

            if (delaiSignature.estNonActif) {
                projet.etat = 3;
            }

            projet.delaiSignature = delaiSignature;
        },
        async ajouterEstimation({ idProjet, nom, regionPrix, template, modele, estDuplicationRapide }) {
            const idProjetTcost = await api.tcost.ajouterProjet({
                nom,
                idRegionPrix: regionPrix.id,
                idTemplate: template?.id,
                idModele: modele?.id,
                estDuplicationRapide: modele?.estDisponiblePourDuplicationRapide && estDuplicationRapide
            });

            const estimation = await api.prospects.ajouterProjetTcost({
                idGroupeProjetPropose: idProjet,
                idProjetTcost,
                idModeleTcost: modele?.id
            });

            const projetsTcost = await api.tcost.getProjets({
                ids: idProjetTcost.toString()
            });

            const projet = this.projets.find((x) => x.id === idProjet);

            const objEstimation = {
                ...estimation,
                ...projetsTcost[0]
            };

            projet.estimations.push(objEstimation);

            return objEstimation;
        },
        async dupliquerEstimation({ idProjet, idEstimation, nom }) {
            const projetSource = this.projets.find((x) => x.id === idProjet),
                estimationSource = projetSource.estimations.find((x) => x.id === idEstimation);

            const idProjetTcost = await api.tcost.dupliquerProjet({
                id: idEstimation,
                nom
            });

            const estimation = await api.prospects.ajouterProjetTcost({
                idGroupeProjetPropose: idProjet,
                idProjetTcost,
                idModele: estimationSource.idModele
            });

            const projetsTcost = await api.tcost.getProjets({
                ids: idProjetTcost.toString()
            });

            const objEstimation = {
                ...estimation,
                ...projetsTcost[0]
            };

            projetSource.estimations.push(objEstimation);

            return objEstimation;
        },
        async supprimerEstimation({ idProjet, idEstimation }) {
            const authStore = useAuthStore(),
                projet = this.projets.find((x) => x.id === idProjet),
                estimation = projet.estimations.find((x) => x.id === idEstimation);

            await api.prospects.supprimerProjet({
                idProjet: estimation.idProjet
            });

            estimation.supprimerPar = authStore.nomComplet;
            estimation.dateFin = formatDate(new Date(), "YYYY-MM-DD HH:mm:ss");
        },
        async ajouterNoteEstimation({ idProjet, idEstimation, note }) {
            const authStore = useAuthStore(),
                projet = this.projets.find((x) => x.id === idProjet),
                estimation = projet.estimations.find((x) => x.id === idEstimation);

            const id = await api.prospects.ajouterNoteProjet({
                idProjet: estimation.idProjet,
                note
            });

            const objNote = {
                id,
                note,
                date: formatDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
                usager: {
                    prenom: authStore.prenom,
                    nom: authStore.nom
                }
            };

            estimation.notes.push(objNote);

            return objNote;
        },
        async supprimerNoteEstimation({ idProjet, idEstimation, idNote }) {
            const projet = this.projets.find((x) => x.id === idProjet),
                estimation = projet.estimations.find((x) => x.id === idEstimation);

            await api.prospects.supprimerNoteProjet({
                idNoteProjet: idNote
            });

            estimation.notes.splice(
                estimation.notes.findIndex((x) => x.id === idNote),
                1
            );
        }
    }
});
