export default (api) => ({
    getProjets: ({ ids = null } = {}) => api.get(`api/tcost/get-projets/${ids}`).then((r) => r.data),
    getProjetPrixVente: ({ id = null } = {}) => api.get(`api/tcost/get-projet-prix-vente/${id}`).then((r) => r.data),
    getProjetTableauCouts: ({ id = null } = {}) => api.get(`api/tcost/get-projet-tableau-couts/${id}`).then((r) => r.data),
    getProjetEstMajRequise: ({ id = null } = {}) => api.get(`api/tcost/get-projet-est-maj-requise/${id}`).then((r) => r.data),
    getProjetParametresNonRepondus: ({ id = null } = {}) => api.get(`api/tcost/get-projet-parametres-non-repondus/${id}`).then((r) => r.data),
    getProjetProduitsInvalides: ({ id = null } = {}) => api.get(`api/tcost/get-projet-produits-invalides/${id}`).then((r) => r.data),
    getRegionsPrix: () => api.get("api/tcost/get-regions-prix").then((r) => r.data),
    getTemplates: () => api.get("api/tcost/get-templates").then((r) => r.data),
    getModeles: () => api.get("api/tcost/get-modeles").then((r) => r.data),
    ajouterProjet: ({ nom = null, idRegionPrix = null, idTemplate = null, idModele = null, estDuplicationRapide = null } = {}) =>
        api.post("api/tcost/ajouter-projet", { nom, idRegionPrix, idTemplate, idModele, estDuplicationRapide }).then((r) => r.data),
    dupliquerProjet: ({ id = null, nom = null } = {}) => api.post(`api/tcost/dupliquer-projet/${id}`, { nom }).then((r) => r.data[0].id),
    getTableauCouts: ({ id = null } = {}) => api.get(`api/tcost/get-projet-tableau-couts/${id}`).then((r) => r.data),
    modifierProjetEtat: ({ id = null, etat = null } = {}) => api.post(`api/tcost/modifier-projet-etat/${id}`, { etat }).then((r) => r.data),
    modifierAdresseProjet: ({ id = null, ville = null, numeroCivique = null, rue = null, codePostal = null, pays = null, province = null } = {}) =>
        api.post(`api/tcost/modifier-adresse-projet-tcost/${id}`, { ville, numeroCivique, rue, codePostal, pays, province }).then((r) => r.data),
    modifierNomProjet: ({ id = null, nom = null } = {}) => api.post(`api/tcost/modifier-nom-projet/${id}`, { nom }).then((r) => r.data)
});
