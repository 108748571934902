import { defineStore } from "pinia";

export const useCreationContratTCOSTStore = defineStore("creationContratTCOST", {
    state: () => ({
        //Loading
        isLoading: true,
        isLoadEtapeUn: false,
        isLoadEtapeDeux: false,
        isLoadEtapeTrois: false,
        isLoadedEtapeQuatre: false,
        isLoadingEtapesVersements: false,
        isLoadedEtapesVersements: false,
        reloadEtapesTrois: false,

        //Fiche Projet
        idProjetTcost: null,
        idProjet: null,
        idGroupeProspect: null,
        estCompagnie: false,
        idGroupeProjetPropose: null,

        //Etape 1
        typesVente: [],
        langues: [],
        seriesPlan: [],
        datesGPP: [],
        adresse: null,
        idAdresse: null,
        selectedTypeVente: null,
        selectedLangue: null,
        selectedCompagnie: null,
        selectedSeriePlan: null,
        adresseConstruction: [],
        dateApproMEP: null,
        dateLivraisonSouhaite: null,
        dateEmmenagementSouhaite: null,
        isLoadingAdresse: false,

        //Etape 2
        tableauCouts: [],
        idZone: null,
        supprimerTaxes: false,
        taxes: [],
        sousTotalProjet: null,
        grandTotalProjet: null,
        idAdresseModifVerif: null,

        //Etape 3
        referent: null,
        conditions: [],
        annexes: [],
        selectedAnnexes: [],
        selectedOptionAnnexe: [],
        etapesVersements: [],
        estSupprimerTaxeVerif: false,
        clients: null
    })
});
