export default (api) => ({
    envoyerSuiviProspectAuClient: ({ idSuiviProspect = null, idGroupeProjetPropose = null, estCompagnie = null } = {}) =>
        api.post("api/courriels/envoyer-suivi-prospect-au-client", { idSuiviProspect, idGroupeProjetPropose, estCompagnie }),
    getAlertesCourriels: () => api.post("api/generiques/stored-procedure/GetAlertesCourrielsV2", {}).then((r) => r.data[0]),
    getDestinatairesAlerteCourriel: ({ idAlerteCourriel = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDestinatairesAlerteCourrielV2", {
                idAlerteCourriel
            })
            .then((r) => r.data[0]),
    supprimerDestinatairesAlerteCourriel: ({ idDestinataireAlerteCourriel = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/SupprimerDestinataireAlerteCourriel", {
                idDestinataireAlerteCourriel
            })
            .then((r) => r.data[0]),
    ajouterDestinataireAlerteCourriel: ({ idAlerteCourriel = null, cc = false, idEmploye = null, idCompagnie = null, courriel = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterDestinataireAlerteCourriel", {
                idAlerteCourriel,
                cc,
                idEmploye,
                idCompagnie,
                courriel
            })
            .then((r) => r.data[0][0][""]),
    getAlertesCourrielsEmploye: ({ idEmploye = null, courriel = null }) =>
        api
            .post("api/generiques/stored-procedure/GetAlertesCourrielsEmploye", {
                idEmploye,
                courriel
            })
            .then((r) => r.data[0]),
    envoieCourrielDirecteurVente: ({ idContrat = null } = {}) => api.post("api/generiques/stored-procedure/EnvoieCourrielDirecteurVente", { idContrat })
});
