export default (api) => ({
    getContratsListeProdDessin: ({ misEnProduction = true, trierParDateProduction = false, numContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getContratsListeProdDessinV2", {
                misEnProduction,
                trierParDateProduction,
                numContrat
            })
            .then((r) =>
                r.data[0].map((x) => ({
                    ...x,
                    taches: JSON.parse(x.taches),
                    notesMEP: JSON.parse(x.notesMEP)
                }))
            ),
    getInfoComplementairePlanUsinage: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getInfoComplementairePlanUsinageV2", {
                idContrat
            })
            .then((r) => r.data[0][0]),
    getComposantesSoumissionAfficherAchat: ({ idContrat = null, idProjetInventaire = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetComposantesSoumissionAfficherAchatV2", {
                idContrat,
                idProjetInventaire
            })
            .then((r) => r.data[0]),
    getTachesPlanUsinageDetaille: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getTachesPlanUsinageDetailleV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    ajoutTempsEstimesTacheContrat: ({ idTache = null, temps = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutTempsEstimesTacheContrat", {
            idTache,
            temps
        }),
    ajoutTacheGanttUsinage: ({ idTask = null, dureeHeure = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutTacheGanttUsinage", {
            idTask,
            dureeHeure
        }),
    getNotesTacheContrat: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetNotesTacheContratV2", {
                idTacheContrat
            })
            .then((r) => r.data[0]),
    ajoutNoteTacheContrat: ({ idTache = null, note = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutNoteTacheContrat", {
                idTache,
                note
            })
            .then((r) => r.data[0][0][""]),
    supprimerNoteTacheContrat: ({ id = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerNoteTacheContrat", {
            id
        }),
    possederContrat: ({ idContrat = null, idTypePossession = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/PossederContratV2", {
                idContrat,
                idTypePossession
            })
            .then((r) => r.data[0][0]),
    libererContrat: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/LibererContratV2", {
            idContrat
        }),
    possederPlan: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/PossederPlanV2", {
                idContrat
            })
            .then((r) => r.data[0][0]),
    libererPlan: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/LibererPlanV2", {
            idContrat
        }),
    getDocumentsPhysiquesContrat: ({ idFichierContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDocumentsPhysiquesContratV2", {
                idFichierContrat
            })
            .then((r) => r.data[0]),
    getFichiersTacheContrat: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetFichiersTacheContratV2", {
                idTacheContrat
            })
            .then((r) => r.data[0]),
    getPunchTache: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetPunchTacheV2", {
                idTacheContrat
            })
            .then((r) => r.data[0]),
    modifierDateFinPunchTache: ({ idRHTaskContrat = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/ModifierDateFinPunchTacheV2", {
                idRHTaskContrat,
                dateFin
            })
            .then((r) => r.data),
    supprimerPunchTache: ({ idRHTaskContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/SupprimerPunchTacheV2", {
                idRHTaskContrat
            })
            .then((r) => r.data),
    modifierTacheContrat: ({ id = null, champ = null, valeur = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/ModifierTacheContratV2", {
                id,
                champ,
                valeur
            })
            .then((r) => r.data),
    debuterTache: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/DebuterTacheV2", {
                idTacheContrat
            })
            .then((r) => r.data),
    modifierDateDebutReelleTacheGantt: ({ idTaskContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierDateDebutReelleTacheGantt", {
            idTaskContrat
        }),
    terminerTache: ({ idTacheContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/TerminerTacheV2", {
                idTacheContrat
            })
            .then((r) => r.data),
    modifierDateFinReelleTacheGantt: ({ idTaskContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierDateFinReelleTacheGantt", {
            idTaskContrat
        }),
    ajouterFichiersTacheContrat: (params = []) =>
        api
            .post("api/generiques/stored-procedure-loop/AjoutFichierTacheContrat", {
                params
            })
            .then((r) => r.data.map((x) => x[0][0])),
    supprimerFichierTacheContrat: ({ id = null } = {}) =>
        api.post("api/generiques/stored-procedure/SupprimerFichierTacheContrat", {
            id
        }),
    getFichiersSoumission: ({ idSoumission = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetFichiersSoumissionV2", {
                idSoumission
            })
            .then((r) => r.data[0]),
    getContratsPourRechercheRapide: ({
        noContrat = null,
        noModele = null,
        noUnite = null,
        nomClient = null,
        prenomClient = null,
        nomCompagnie = null,
        vendeur = null,
        bureau = null,
        approbateur = null,
        dessinateur = null,
        autreIntervenant = null,
        adresse = null,
        notesPlan = null,
        caracteristiquesPlan = null
    }) =>
        api
            .post("api/generiques/stored-procedure/GetContratRechercheRapide", {
                noContrat,
                noModele,
                noUnite,
                nomClient,
                prenomClient,
                nomCompagnie,
                vendeur,
                bureau,
                approbateur,
                dessinateur,
                autreIntervenant,
                adresse,
                notesPlan,
                caracteristiquesPlan
            })
            .then((r) => r.data[0]),
    getContratsSansResponsable: ({ idPoste = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetContratsSansResponsableV2", {
                idPoste
            })
            .then((r) => r.data[0]),
    ajouterResponsableContrat: ({ idContrat = null, idEmploye = null, idPoste = null, principale = false } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterResponsableContratV2", {
            idContrat,
            idEmploye,
            idPoste,
            principale
        }),
    getContratsSansICQActif: () => api.post("api/generiques/stored-procedure/GetContratsSansICQActif").then((r) => r.data[0]),
    getContratsSansChargeProjetActif: () => api.post("api/generiques/stored-procedure/GetContratsSansChargeProjetActif").then((r) => r.data[0]),
    getContratsSansCPActifPourDirecteurProjet: () => api.post("api/generiques/stored-procedure/GetContratsSansCPActifPourDirecteurProjet").then((r) => r.data[0]),
    getContratsSansICQActifPourDirecteurProjet: () => api.post("api/generiques/stored-procedure/GetContratsSansICQActifPourDirecteurProjet").then((r) => r.data[0]),
    getContratsProductionUsine: ({ dateMin = null, dateMax = null, idCompagnie = null, idTypeContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetContratsProductionUsine", {
                dateMin,
                dateMax,
                idCompagnie,
                idTypeContrat
            })
            .then((r) => r.data[0].map((x) => ({ ...x, notes: JSON.parse(x.notes), fichiersInfos: JSON.parse(x.fichiersInfos) }))),
    rechercherContratProductionUsine: ({ numContrat = null, idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/RechercherContratProductionUsine", {
                numContrat,
                idCompagnie
            })
            .then((r) => r.data[0]),
    getContratsCeduleLivraison: ({ dateMin = null, dateMax = null, idCompagnie = null, idTypeContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetContratsCeduleLivraison", { dateMin, dateMax, idCompagnie, idTypeContrat })
            .then((r) => r.data[0].map((x) => ({ ...x, notes: JSON.parse(x.notes), fichiersInfos: JSON.parse(x.fichiersInfos) }))),
    rechercherContratCeduleLivraison: ({ numContrat = null, idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/RechercherContratCeduleLivraisonV2", {
                numContrat,
                idCompagnie
            })
            .then((r) => r.data[0]),
    ajouterNoteContrat: ({ idContrat = null, note = null, idTypeNote = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterNoteContrat", {
                idContrat,
                note,
                idTypeNote
            })
            .then((r) => r.data[0][0].idNoteContrat),
    modifierNoteContrat: ({ idNoteContrat = null, estVue = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierNoteContrat", {
            idNoteContrat,
            estVue
        }),
    setDateProductionUsinePrevu: ({ idContrat = null, date = null, idProjetInventaire = null, estLivraisonParBateau = false, dateDebutDessinUsinagePrevue = null } = {}) =>
        api.post("api/generiques/stored-procedure/SetDateProductionUsinePrevu", {
            idContrat,
            date,
            idProjetInventaire,
            estLivraisonParBateau,
            dateDebutDessinUsinagePrevue
        }),
    modifierContratNbMur: ({ idContrat = null, nbMur = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierContratNbMur", {
            idContrat,
            nbMur
        }),
    ajoutHeuresUsineReel: ({ idContrat = null, heureUsine = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutHeuresUsineReel", {
            idContrat,
            heureUsine
        }),
    commencerProduction: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/CommencerProduction", {
            idContrat
        }),
    terminerProduction: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/TerminerProduction", {
            idContrat
        }),
    ajoutDateLivraisonReelleContrat: ({ idContrat = null, dateLivraisonReelle = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutDateLivraisonReelleContrat", { idContrat, dateLivraisonReelle }),
    modifierLivraisonParBateauContrat: ({ idContrat = null, estLivraisonParBateau = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierLivraisonParBateauContrat", { idContrat, estLivraisonParBateau }),
    getContratsCalendrierDessinUsinage: ({ dateMin = null, dateMax = null, idCompagnie = null, idTypeContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetContratsCalendrierDessinUsinageV2", { dateMin, dateMax, idCompagnie, idTypeContrat }).then((r) => r.data[0]),
    rechercherContratCalendrierDessinUsinage: ({ numContrat = null, idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/RechercherContratCalendrierDessinUsinage", {
                numContrat,
                idCompagnie
            })
            .then((r) => r.data[0]),
    modifierContratDateDebutDessinUsinagePrevue: ({ idContrat = null, dateDebutDessinUsinagePrevue = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierContratDateDebutDessinUsinagePrevue", {
            idContrat,
            dateDebutDessinUsinagePrevue
        }),
    debuterContratDessinUsinage: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/DebuterContratDessinUsinage", {
            idContrat
        }),
    terminerContratDessinUsinage: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/TerminerContratDessinUsinage", {
            idContrat
        }),
    getProblemesNonRegles: ({ idTypeProbleme = null, idProbleme = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProblemesNonReglesV3", { idTypeProbleme, idProbleme })
            .then((r) => r.data.flat().map((x) => ({ ...x, transfererA: JSON.parse(x.transfererA) }))),
    getCompagniesProbleme: () => api.post("api/generiques/stored-procedure/GetCompagniesProblemeV2").then((r) => r.data[0]),
    getSitesProbleme: ({ idCompagnie = null, idTypeProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetSitesProblemeV2", { idCompagnie, idTypeProbleme }).then((r) => r.data[0]),
    getDepartementsProbleme: ({ idSite = null, idTypeProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetDepartementsProblemeV2", { idSite, idTypeProbleme }).then((r) => r.data[0]),
    getEmployesProbleme: ({ idDepartement = null, idTypeProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetEmployesProblemeV2", { idDepartement, idTypeProbleme }).then((r) => r.data[0]),
    transfererProbleme: ({ idProbleme = null, idEmploye = null, dateRequilSolution = null, responsable = false } = {}) =>
        api.post("api/generiques/stored-procedure/TransfererProbleme", { idProbleme, idEmploye, dateRequilSolution, responsable }),
    envoyerCourrielProblemeNonRegle: ({ idProbleme = null, idTypeProbleme = null, noEtape = null } = {}) =>
        api.post("api/generiques/stored-procedure/EnvoyerCourrielProblemeNonRegle", { idProbleme, idTypeProbleme, noEtape }),
    priseEnChargeProbleme: ({ idProbleme = null, idEmploye = null, idCompagnie = null, usagerModif = null } = {}) =>
        api.post("api/generiques/stored-procedure/PriseEnChargeProbleme", { idProbleme, idEmploye, idCompagnie, usagerModif }),
    getSommaireProbleme: ({ idProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetSommaireProbleme", { idProbleme }).then((r) => ({
            composantes: r.data[0],
            fichiers: r.data[1]
        })),
    getNotesProbleme: ({ idProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetNotesProblemeV2", { idProbleme }).then((r) => r.data[0].map((x) => ({ ...x, fichiers: JSON.parse(x.fichiers) }))),
    getResponsablesProbleme: ({ idProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetResponsablesProbleme", { idProbleme }).then((r) => ({
            responsables: JSON.parse(r.data[0][0].responsables)
        })),
    ajouterSuiviProbleme: ({ idProbleme = null, suivi = null, fichiers = [], responsables = [] } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterSuiviProblemeV2", {
                idProbleme,
                suivi,
                fichiers: {
                    type: "Ids",
                    rows: fichiers
                },
                responsables: {
                    type: "Ids",
                    rows: responsables
                }
            })
            .then((r) => r.data[0][0].idNoteProbleme),
    getContratsPlanificationMEP: () => api.post("api/generiques/stored-procedure/GetContratsPlanificationMEPV2").then((r) => r.data[0]),
    getEtatsContratsPreMEP: () => api.post("api/generiques/stored-procedure/GetEtatsContratsPreMEPV2").then((r) => r.data[0]),
    getDetailsContratPlanificationMEP: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetDetailsContratPlanificationMEP", { idContrat }).then((r) => ({
            contrat: r.data[0].map((x) => ({
                ...x,
                typeContrat: JSON.parse(x.typeContrat)
            })),
            notes: r.data[1]
        })),
    getTypesAttentesContrats: () => api.post("api/generiques/stored-procedure/GetTypesAttentesContrats").then((r) => r.data[0]),
    getHistoriquesEtatsContratPreMEP: ({ idContrat = null } = {}) => api.post("api/generiques/stored-procedure/GetHistoriquesEtatsContratPreMEP", { idContrat }).then((r) => r.data[0]),
    getHistoriquesTypesAttentesContrat: ({ idContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetHistoriquesTypesAttentesContrat", { idContrat }).then((r) => r.data[0]),
    getHistoriquesDateContrat: ({ idContrat = null, typeDate = null } = {}) =>
        api.post("api/generiques/stored-procedure/GetHistoriquesDateContrat", { idContrat, typeDate }).then((r) => r.data[0]),
    modifierEtatContratPreMEP: ({ idContrat = null, idEtatContratPreMEP = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierEtatContratPreMEP", { idContrat, idEtatContratPreMEP }),
    modifierTypeAttenteContrat: ({ idContrat = null, idTypeAttenteContrat = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierTypeAttenteContrat", { idContrat, idTypeAttenteContrat }),
    ajoutDateApproximativeMEP: ({ idContrat = null, date = null } = {}) => api.post("api/generiques/stored-procedure/AjoutDateApproximativeMEP", { idContrat, date }),
    setDateLivraisonSouhaitee: ({ idContrat = null, date = null } = {}) => api.post("api/generiques/stored-procedure/SetDateLivraisonSouhaitee", { idContrat, date }),
    modifierDateEmmenagementPrevuContrat: ({ idContrat = null, dateEmmenagementPrevu = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierDateEmmenagementPrevuContrat", { idContrat, dateEmmenagementPrevu }),
    getContratsSuiviRecevables: () => api.post("api/generiques/stored-procedure/GetContratsSuiviRecevablesV2").then((r) => r.data[0]),
    modifierContratEstEnSurveillance: ({ idContrat = null, estEnSurveillance = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierContratEstEnSurveillance", { idContrat, estEnSurveillance }),
    getNotesSuiviFinancier: ({ idSuiviFinancier = null, idTypeNoteFinancier = 1 } = {}) =>
        api.post("api/generiques/stored-procedure/GetNotesSuiviFinancierV2", { idSuiviFinancier, idTypeNoteFinancier }).then((r) => r.data[0]),
    ajouterNoteSuiviFinancier: ({ idSuiviFinancier = null, note = null, idTypeNoteFinancier = 1 } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterNoteSuiviFinancier", { idSuiviFinancier, note, idTypeNoteFinancier }).then((r) => r.data[0][0].idNote),
    getContratsTableauBordChargesProjets: ({ mep = null } = {}) => api.post("api/generiques/stored-procedure/GetContratsTableauBordChargesProjets", { mep }).then((r) => r.data[0]),
    getContratsTableauBordInspecteurs: () => api.post("api/generiques/stored-procedure/GetContratsTableauBordInspecteurs").then((r) => r.data[0]),
    getContratsNonApprouves: () => api.post("api/generiques/stored-procedure/GetContratsNonApprouvesV2").then((r) => r.data[0]),
    getContratsOuvrables: () => api.post("api/generiques/stored-procedure/GetContratsOuvrables").then((r) => r.data[0]),
    ouvrirContrat: ({ idContrat = null } = {}) => api.post("api/generiques/stored-procedure/OuvrirContrat", { idContrat }),
    getContratsDessin: ({ idGroupePrioritePlan = 1 } = {}) =>
        api.post("api/generiques/stored-procedure/GetContratsDessinV2", { idGroupePrioritePlan }).then((r) =>
            r.data[0].map((x) => ({
                ...x,
                versions: JSON.parse(x.versions),
                fichiers: JSON.parse(x.fichiers),
                reservations: JSON.parse(x.reservations)
            }))
        ),
    modifierDegreModification: ({ idPrioritePlan = null, idDegre = null } = {}) => api.post("api/generiques/stored-procedure/ModifierDegreModification", { idPrioritePlan, idDegre }),
    modifierDateDonneurOrdre: ({ idPrioritePlan = null, date = null } = {}) => api.post("api/generiques/stored-procedure/ModifierDateDonneurOrdre", { idPrioritePlan, date }),
    modifierOrdrePrioritePlan: ({ idPrioritePlan = null, ordre = null } = {}) => api.post("api/generiques/stored-procedure/ModifierOrdrePrioritePlan", { idPrioritePlan, ordre }),
    confirmerDateDonneurOrdre: ({ idPrioritePlan = null, date = null, tempsEstime = null, simulation = false } = {}) =>
        api.post("api/generiques/stored-procedure/ConfirmerDateDonneurOrdre", { idPrioritePlan, date, tempsEstime, simulation }),
    ajouterTacheGantt_DessinArchitecture: ({
        idContrat = null,
        nom = null,
        dateDebutPrevue = null,
        dateFinPrevue = null,
        dureeHeures = null,
        idTacheParent = null,
        ressources = null,
        idPrioritePlan = null,
        sansDateSortie = false
    } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterTacheGantt_DessinArchitecture", {
            idContrat,
            nom,
            dateDebutPrevue,
            dateFinPrevue,
            dureeHeures,
            idTacheParent,
            ressources,
            idPrioritePlan,
            sansDateSortie
        }),
    modifierEtatDossierPlan: ({ idPrioritePlan = null, etat = null } = {}) => api.post("api/generiques/stored-procedure/ModifierEtatDossierPlan", { idPrioritePlan, etat }),
    modifierSansReservationPlan: ({ idPrioritePlan = null, sansReservation = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierSansReservationPlan", { idPrioritePlan, sansReservation }),
    ajoutReservationPlan: ({ idPrioritePlan = null, idCompangie = null, idEmploye = null, ordre = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutReservationPlan", { idPrioritePlan, idCompangie, idEmploye, ordre }).then((r) => r.data[0][0].idReservation),
    supprimerReservation: ({ idReservation = null } = {}) => api.post("api/generiques/stored-procedure/SupprimerReservation", { idReservation }),
    modifierOrdreReservationsPlans: ({ reservations = [] } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierOrdreReservationsPlans", {
            reservations: {
                type: "OrdresItems",
                rows: reservations
            }
        }),
    ajoutNoteSuperviseurPlan: ({ idContrat = null, note = null, idProjet = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutNoteSuperviseurPlan", { idContrat, note, idProjet }),
    enregistrerDateReceptionDessinAtelier: ({ idContrat = null, idComposante = null, date = null } = {}) =>
        api.post("api/generiques/stored-procedure/EnregistrerDateReceptionDessinAtelier", { idContrat, idComposante, date }),
    getContratsRequete: ({
        idCompagnie = null,
        idSite = null,
        idEmploye = null,
        annule = null,
        mep = null,
        livre = null,
        dateSignatureDe = null,
        dateSignatureA = null,
        dateLivReelDe = null,
        dateLivReelA = null,
        dateLivPrevDe = null,
        dateLivPrevA = null,
        dateMepReelDe = null,
        dateMepReelA = null,
        dateProductionDe = null,
        dateProductionA = null,
        dateAnnuleDe = null,
        dateAnnuleA = null,
        colonne = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetContratsRequeteV2", {
                idCompagnie,
                idSite,
                idEmploye,
                annule,
                mep,
                livre,
                dateSignatureDe,
                dateSignatureA,
                dateLivReelDe,
                dateLivReelA,
                dateLivPrevDe,
                dateLivPrevA,
                dateMepReelDe,
                dateMepReelA,
                dateProductionDe,
                dateProductionA,
                dateAnnuleDe,
                dateAnnuleA,
                colonne
            })
            .then((r) => r.data[0]),
    comparatifPrixERPvsMaximum: ({
        numContrat = null,
        dateSignatureDe = null,
        dateSignatureA = null,
        dateLivreDe = null,
        dateLivreA = null,
        contratLivre = null,
        tolerance = null,
        idCompagnie = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/ComparatifPrixERPvsMaximum", {
                numContrat,
                dateSignatureDe,
                dateSignatureA,
                dateLivreDe,
                dateLivreA,
                contratLivre,
                tolerance,
                idCompagnie
            })
            .then((r) => r.data[0]),
    contratExiste: ({ numContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/EstContratExiste", {
                numContrat
            })
            .then((r) => r.data),
    getCategoriesCaracteristiquesPlan: ({ idCompagnie = null, idFichierContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetCategorieTag", {
                idCompagnie,
                idFichierContrat
            })
            .then((r) => r.data[0]),
    getTagsDessin: ({ idCategorie = null, idFichierContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetTagsDessin", {
                idCategorie,
                idFichierContrat
            })
            .then((r) => r.data[0]),
    getContratAvecProduitsNonICC: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetContratsAvecListeChargementPasDansICC", {
                idCompagnie
            })
            .then((r) => r.data[0]),
    GetSectionsAvecProduitsPasDansICC: ({ idListeChargement = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetSectionsAvecProduitsPasDansICC", {
                idListeChargement
            })
            .then((r) => r.data[0]),
    getNotesSectionContrat: ({ idListeDeChargement = null, idSection = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getNotesSectionContrat", {
                idListeDeChargement,
                idSection
            })
            .then((r) => r.data[0]),
    AjoutNoteSectionContrat: ({ idListeDeChargement = null, idSection = null, note = null, highLight = false } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutNoteSectionContrat", {
                idListeDeChargement,
                idSection,
                note,
                highLight
            })
            .then((r) => r.data[0]),
    GetProduitsListeChargementPasDansICC: ({ idListeChargement = null, idSection = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProduitsListeChargementPasDansICC", {
                idListeChargement,
                idSection
            })
            .then((r) => r.data[0]),
    AjoutProduitListeChargementICC: ({ idProduit = 0, checked = 0 } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutProduitListeChargementICC", {
            idProduit,
            checked
        }),
    getEtapesConstructionsContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetEtapesConstructionsContratV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetContratV2", {
                idContrat
            })
            .then((r) => r.data[0]),
    getTypesContrat: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getTypesContrat", {
                idCompagnie
            })
            .then((r) => r.data[0]),
    getTypesContrats: () => api.post("api/generiques/stored-procedure/GetTypesContrats").then((r) => r.data[0]),
    modifierLatLong: ({ idAdresse = null, latitude = null, longitude = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/ModifierZonne", {
                idAdresse,
                latitude,
                longitude
            })
            .then((r) => r.data[0]),
    getContratVenduDansZoneDefinie: ({ idCompagnie = null, idZonesDefinies = null, dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getContratVenduDansZoneDefinie", {
                idCompagnie,
                dateDebut,
                dateFin,
                idZonesDefinies
            })
            .then((r) => r.data[0]),
    getContratLivreDansZoneDefinie: ({ idCompagnie = null, idZonesDefinies = null, dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getContratLivreDansZoneDefinie", {
                idCompagnie,
                dateDebut,
                dateFin,
                idZonesDefinies
            })
            .then((r) => r.data[0]),
    getNotesSuperviseurPlan: ({ idContrat = null, idProjet = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getNotesSuperviseurPlan", {
                idContrat,
                idProjet
            })
            .then((r) => r.data[0]),
    modifierNoteSuperviseurPlan: ({ idContrat = null, derniereNote = null, note = null, idProjet = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/modifierNoteSuperviseurPlan", {
                idContrat,
                derniereNote,
                note,
                idProjet
            })
            .then((r) => r.data[0]),
    modifierDateSolutionRequiseProbleme: ({ idProbleme = null, dateSolutionRequiseLe = null, idUsager = null } = {}) =>
        api.post("api/generiques/stored-procedure/ModifierDateSolutionRequiseProbleme", { idProbleme, dateSolutionRequiseLe, idUsager }),
    getDateProblemesHistorique: ({ idProbleme = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDateProblemesHistorique", {
                idProbleme
            })
            .then((r) => r.data[0]),
    getProblemesContrat: ({ idProbleme = null, idTypeProbleme } = {}) =>
        api
            .post("api/generiques/stored-procedure/getProblemesContrat", {
                idProbleme,
                idTypeProbleme
            })
            .then((r) => r.data[0]),
    ajoutAutreSolutionProbleme: ({ idProbleme = null, solution = null, idUsager } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutAutreSolutionProbleme", {
            idProbleme,
            solution,
            idUsager
        }),
    regleProbleme: ({ idProbleme = null, coutMateriel = null, coutMo = null } = {}) =>
        api.post("api/generiques/stored-procedure/RegleProbleme", {
            idProbleme,
            coutMateriel,
            coutMo
        }),
    getProblemesRegles: ({ idTypeProbleme = null, dateDebut = null, dateFin = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProblemesRegles", { idTypeProbleme, dateDebut, dateFin })
            .then((r) => r.data.flat().map((x) => ({ ...x, transfererA: JSON.parse(x.transfererA) }))),
    getTypesSuiviesChantiers: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getTypesSuiviesChantiers", {
                idCompagnie
            })
            .then((r) => r.data[0]),
    getListeSatisfaction: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getListeSatisfaction", {
                idCompagnie
            })
            .then((r) => r.data[0]),
    getCourrielsSuiviChantier: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getCourrielsSuiviChantier", {
                idContrat
            })
            .then((r) => r.data[0]),
    ajoutSuivieChantier: ({ idContrat = null, idTypeSuivieChantier = null, note = null, idSatisfactionClient = null, idCompagnieUsager = null, idEntiteContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutSuivieChantier", {
                idContrat,
                idTypeSuivieChantier,
                note,
                idSatisfactionClient,
                idCompagnieUsager,
                idEntiteContrat
            })
            .then((r) => r.data[0]),
    ajoutFichierSuivieChantier: ({ idSuivieChantier = null, idFichier = null, note = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutFichierSuivieChantier", {
            idSuivieChantier,
            idFichier,
            note
        }),
    envoyerCourrielAjoutDiscussion: ({ to = [], idSuiviChantier = null, idContrat = null, suivi = null } = {}) =>
        api.post("api/generiques/stored-procedure/EnvoyerCourrielAjoutDiscussion", {
            to: {
                type: "Textes",
                rows: to
            },
            idSuiviChantier,
            idContrat,
            suivi
        }),
    estTypesSuiviesChantiersMEP: ({ idTypeSuivieChantier = null } = {}) =>
        api
            .post("api/generiques/scalar-function/EstTypesSuiviesChantiersMEP", {
                idTypeSuivieChantier
            })
            .then((r) => r.data),
    ajoutCourrielSuivieChantier: ({ idAdresseCourriel = null, idSuivieChantier = null, clientSatisfait = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutCourrielSuivieChantier", {
            idAdresseCourriel,
            idSuivieChantier,
            clientSatisfait
        }),
    getResponsablesProblemesHistorique: ({ idProbleme = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetResponsablesProblemesHistorique", {
                idProbleme
            })
            .then((r) => r.data[0]),
    getNumContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/getNumContrat", {
                idContrat
            })
            .then((r) => r.data),
    getIdTypeContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/getIdTypeContrat", {
                idContrat
            })
            .then((r) => r.data),
    getCategories: ({ idCompagnie = null, idTypeContrat = null, idZone = null, dtp = null, actif = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetCategories", {
                idCompagnie,
                idTypeContrat,
                idZone,
                dtp,
                actif
            })
            .then((r) => r.data[0]),
    getListeChargementContrat: ({ idContrat = null, srtChercherProduit = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getListeChargementContrat", {
                idContrat,
                srtChercherProduit
            })
            .then((r) => r.data[0]),
    ajoutProbleme: ({
        idCompagnie = null,
        description = null,
        idResponsable = null,
        idProspect = null,
        idFournisseurCompagnie = null,
        dateTransfere = null,
        idStatut = null,
        idTypeProbleme = null,
        dateRecu,
        estManqueMatUsineSAV = false,
        titreProbleme = null,
        dateSolutionRequise = null,
        transfererASoiMeme = false
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutProbleme", {
                idCompagnie,
                description,
                idResponsable,
                idProspect,
                idFournisseurCompagnie,
                dateTransfere,
                idStatut,
                idTypeProbleme,
                dateRecu,
                estManqueMatUsineSAV,
                titreProbleme,
                dateSolutionRequise,
                transfererASoiMeme
            })
            .then((r) => r.data[0][0]),
    ajoutFichierProbleme: ({ idProbleme = null, idFichier = null, note = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutFichierProbleme", {
            idProbleme,
            idFichier,
            note
        }),
    ajoutProblemeContrat: ({ idProbleme = null, idContrat = null, idEntiteContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutProblemeContrat", {
                idProbleme,
                idContrat,
                idEntiteContrat
            })
            .then((r) => r.data[0]),
    ajoutComposanteProbleme: ({
        idProbleme = null,
        idComposante = null,
        autreProduit = null,
        note = null,
        idProduit = null,
        idCategorie = null,
        quantiteDemande = null,
        idUniteMesureQuantite = null,
        requisLe = null,
        idDepartementResponsable = null,
        idCauseDeManque = null,
        prixUn = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutComposanteProbleme", {
                idProbleme,
                idComposante,
                autreProduit,
                note,
                idProduit,
                idCategorie,
                quantiteDemande,
                idUniteMesureQuantite,
                requisLe,
                idDepartementResponsable,
                idCauseDeManque,
                prixUn
            })
            .then((r) => r.data[0]),
    envoyerCourrielAjoutProbleme: ({ idContrat = null, to = [], idProbleme = null, idTypeProbleme = null } = {}) =>
        api.post("api/generiques/stored-procedure/EnvoyerCourrielAjoutProbleme", {
            idContrat,
            to: {
                type: "Textes",
                rows: to
            },
            idProbleme,
            idTypeProbleme
        }),
    getLangues: ({ idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetLangues", {
                idCompagnie
            })
            .then((r) => r.data[0]),
    getAdresseFormater: ({ idAdresse = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetAdresseFormater", {
                idAdresse
            })
            .then((r) => r.data[0][0]),
    getAdresseCompagnieProspect: ({ idCompagnieProspect = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetAdresseCompagnieProspect", {
                idCompagnieProspect
            })
            .then((r) => r.data[0][0]),
    getAdresseGroupeProspect: ({ idGroupeProspect = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetAdresseGroupeProspect", {
                idGroupeProspect
            })
            .then((r) => r.data[0][0]),
    getZoneAdresse: ({ idAdresse = null } = {}) =>
        api
            .post("api/generiques/scalar-function/GetZoneAdresse", {
                idAdresse
            })
            .then((r) => r.data),
    getDatesGroupeProjetPropose: ({ idGroupeProjetPropose = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetDatesGroupeProjetPropose", {
                idGroupeProjetPropose
            })
            .then((r) => r.data[0][0]),
    calculerTaxesContratTcost: ({ sousTotal = null, idZone = null, idCompagnie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/CalculerTaxesContratTcost", {
                sousTotal,
                idZone,
                idCompagnie
            })
            .then((r) => r.data),
    getAnnexes: ({ idCompagnie = null, idTypeContrat = null, idZone = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getAnnexes", {
                idCompagnie,
                idTypeContrat,
                idZone
            })
            .then((r) => r.data[0]),
    getOptionsAnnexe: ({ idAnnexe = null, idZone = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getOptionsAnnexe", {
                idAnnexe,
                idZone
            })
            .then((r) => r.data[0]),
    getSousOptionsAnnexe: ({ idOptionAnnexe = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getSousOptionsAnnexe", {
                idOptionAnnexe
            })
            .then((r) => r.data[0]),
    getComplementairesAnnexe: ({ idAnnexe = null, idTypeComplementaire } = {}) =>
        api
            .post("api/generiques/stored-procedure/getComplementairesAnnexeV2", {
                idAnnexe,
                idTypeComplementaire
            })
            .then((r) => r.data[0]),
    getReferent: ({ idCompagnie = null, contrat } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetProspectsContrat", {
                idCompagnie,
                contrat
            })
            .then((r) => r.data[0][0]),
    getEtapesVersementsContratNouveauTcost: ({ idOption = null, idProjetTCOST = null, idZone = null, calculerTaxe = true } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetEtapesVersementsContratNouveauTcost", {
                idOption,
                idProjetTCOST,
                idZone,
                calculerTaxe
            })
            .then((r) => r.data[0]),
    getEtapesVersementsContratTcost: ({ idOption = null, idProjet = null, idZone = null, idVersionsProjetEstimation = null, calculerTaxe = true } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetEtapesVersementsContratTcost", {
                idOption,
                idProjet,
                idZone,
                idVersionsProjetEstimation,
                calculerTaxe
            })
            .then((r) => r.data[0]),
    enregistrerTableauCoutTCOST: ({ idProjetTcost = null, nomCategorie = null, totalMateriel = null, totalInstallation = null } = {}) =>
        api.post("api/generiques/stored-procedure/EnregistrerTableauCoutTCOST", {
            idProjetTcost,
            nomCategorie,
            totalMateriel,
            totalInstallation
        }),
    ajouterNouveauContratTcost: ({
        idCompagnie = null,
        idProjet = null,
        idGroupeProspect = null,
        idSeriePlan = null,
        idReferenceClient = null,
        idReferenceCompagnie = null,
        idContratReference = null,
        estCompagnie = false,
        idTypeContrat = null,
        idLangue = null,
        dateMEP = null,
        dateLivraison = null,
        dateEmmenagement = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjouterNouveauContratTcost", {
                idCompagnie,
                idProjet,
                idGroupeProspect,
                idSeriePlan,
                idReferenceClient,
                idReferenceCompagnie,
                idContratReference,
                estCompagnie,
                idTypeContrat,
                idLangue,
                dateMEP,
                dateLivraison,
                dateEmmenagement
            })
            .then((r) => r.data[0][0][""]),
    ajoutCategorieInclusContratTcost: ({ idContrat = null, inclureTaxe = true, idZone = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutCategorieInclusContratTcost", {
            idContrat,
            inclureTaxe,
            idZone
        }),
    ajouterAdresseConstructionContratTcost: ({ idContrat = null, idAdresse = null, idCompagnie = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjouterAdresseConstructionContratTcost", {
            idContrat,
            idAdresse,
            idCompagnie
        }),
    ajoutAnnexeContratTcost: ({ idContrat = null, idAnnexe = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutAnnexeContratTcost", {
            idContrat,
            idAnnexe
        }),
    ajoutOptionAnnexeContratTcost: ({ idContrat = null, idOptionAnnexe = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutOptionAnnexeContratTcost", {
            idContrat,
            idOptionAnnexe
        }),
    ajoutAnnexesComplementairesContratTcost: ({ idContrat = null, idAnnexeComplementaire = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutAnnexesComplementairesContratTcost", {
            idContrat,
            idAnnexeComplementaire
        }),
    ajoutAnnexesComplementairesAutresContratTcost: ({ idContrat = null, description = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutAnnexesComplementairesAutresContratTcost", {
            idContrat,
            description
        }),
    ajoutEtapesVersementsContratTcost: ({ idContrat = null, idEtapeVersement = null, pourcentageReel = null, montantAPayer = null, idCompagnie = null } = {}) =>
        api.post("api/generiques/stored-procedure/AjoutEtapesVersementsContratTcost", {
            idContrat,
            idEtapeVersement,
            pourcentageReel,
            montantAPayer,
            idCompagnie
        }),
    getIdComplementUsager: ({ idUsager = null } = {}) =>
        api
            .post("api/generiques/scalar-function/GetIdComplementUsager", {
                idUsager
            })
            .then((r) => r.data),
    getClientsProjet: ({ idProjet = null } = {}) =>
        api
            .post("api/generiques/scalar-function/getClientsProjet", {
                idProjet
            })
            .then((r) => r.data),
    getRapportContratVente: ({ idCompagnie = null, idCompagnieContrat = null, idContrat = null, idLangue = null, idProjetTcost = null, avecTaxes = true, idTypeContrat = null }) =>
        api.post("api/pdf/contrat-vente", { idCompagnie, idCompagnieContrat, idContrat, idLangue, idProjetTcost, avecTaxes, idTypeContrat }).then((r) => r.data),
    getInfosFacture: ({ idProjet = null, idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/getInfosFacture", {
                idProjet,
                idContrat
            })
            .then((r) => r.data[0][0]),
    calTaxe: ({ idZone = null, idCompagnie = null, montant = null, categorie = null, varSupp = false, taxeExclut = null, idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/CalTaxe", {
                idZone,
                idCompagnie,
                montant,
                categorie,
                varSupp,
                taxeExclut,
                idContrat
            })
            .then((r) => r.data[0]),
    getTaxesApplicable: ({ idCompagnie = null, idZoneLivraison = null, idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/GetTaxesApplicable", {
                idCompagnie,
                idZoneLivraison,
                idContrat
            })
            .then((r) => r.data[0]),
    ajoutFacture: ({
        numero = null,
        licence = null,
        dateEntreeICC = null,
        idEtat = null,
        prixSsTot = null,
        prixGdTot = null,
        devise,
        regleTaxe,
        commission = null,
        dateSignature = null
    } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutFacture", {
                numero,
                licence,
                dateEntreeICC,
                idEtat,
                prixSsTot,
                prixGdTot,
                devise,
                regleTaxe,
                commission,
                dateSignature
            })
            .then((r) => r.data[0][0]),
    getDeviseContrat: ({ idContrat = null } = {}) =>
        api
            .post("api/generiques/scalar-function/GetDeviseContrat", {
                idContrat
            })
            .then((r) => r.data),
    ajoutDetailleFacture: ({ idFacture = null, quantite = null, description = null, prixUnite = null, devise = null, regleTaxe = null, categorie = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutDetailleFacture", {
                idFacture,
                quantite,
                description,
                prixUnite,
                devise,
                regleTaxe,
                categorie
            })
            .then((r) => r.data[0][0]),
    ajoutTaxeFacture: ({ idFacture = null, idTaxe = null, montant = null, typeMontant = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutTaxeFacture", {
                idFacture,
                idTaxe,
                montant,
                typeMontant
            })
            .then((r) => r.data[0][0]),
    ajoutFactureContrat: ({ idFacture = null, idContrat = null } = {}) =>
        api
            .post("api/generiques/stored-procedure/AjoutFactureContrat", {
                idFacture,
                idContrat
            })
            .then((r) => r.data[0][0]),
    getPdfFacture: ({ idFacture = null, idLangue = null, idTypeFacture = null, idCompagnie = null, idContrat = null, produits = [], sousTotal = null, grandTotal = null, taxes = [] }) =>
        api.post("api/pdf/facture", { idFacture, idLangue, idTypeFacture, idCompagnie, idContrat, produits, sousTotal, grandTotal, taxes }).then((r) => r.data),
    modifierIdFichierCreationFacture: ({ idFichier = null, idFacture = null } = {}) =>
        api.post("api/generiques/stored-procedure/modifierIdFichierCreationFacture", {
            idFichier,
            idFacture
        })
});
