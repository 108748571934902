<template>
    <q-input v-model="value" @click="$refs.qDateProxy.show()" dense>
        <template v-slot:[slotName]>
            <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="qDateProxy">
                    <q-date
                        v-model="value"
                        today-btn
                        @update:model-value="$refs.qDateProxy.hide()"
                        mask="YYYY-MM-DD"
                        :options="(date) => (limitationMin ? new Date(date) >= new Date(new Date().setHours(0, 0, 0, 0)) : date > '1950/01/01')"
                        :navigation-min-year-month="navigationMinYearMonth"
                    >
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Fermer" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-input>
</template>
<script>
export default {
    props: {
        modelValue: {
            type: String
        },
        iconRight: {
            type: Boolean,
            default: false
        },
        navigationMinYearMonth: {
            type: String,
            default: ""
        },
        limitationMin: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update:modelValue"],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        },
        slotName() {
            return this.iconRight ? "append" : "prepend";
        }
    }
};
</script>
